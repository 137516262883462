<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex align-center gantt-task-item pr-2"
      :style="{
        'padding-left': `${parents.length * 10}px`,
      }"
    >
      <v-icon
        class="mx-2 ant-icon"
        small
        :class="{
          'mdi-rotate-90': !task.collapsed,
          'ant-gantt-collapse-toggle-hide':
            !task.hasChildren || !canLoadChildren,
          'mdi-spin': task.loadChildren,
        }"
        @click.stop="toggleCollapse({ task: task, parents: parentTreeItems })"
      >
        {{ task.loadChildren ? 'mdi-loading' : 'mdi-chevron-right' }}</v-icon
      >
      <v-tooltip v-if="showSbs" bottom>
        <template #activator="{ on }">
          <v-chip x-small v-on="on">{{ task.sbscode.code }}</v-chip>
        </template>
        <span>SBS code</span>
      </v-tooltip>
      <div v-else style="font-size: 12px; color: grey; min-width: 30px">
        #{{ task.number }}
      </div>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon v-if="task.status === 'open'" class="mx-1"
            >mdi-checkbox-blank-circle-outline</v-icon
          >
          <v-icon v-if="task.status === 'closed'" color="success" class="mx-1"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon v-if="task.status === 'canceled'" color="warning" class="mx-1"
            >mdi-cancel</v-icon
          >
        </template>
        <span>{{ task.status }}</span>
      </v-tooltip>
      <div
        v-if="
          ['workflow', 'rfi', 'dhme-quality-assurance'].includes(
            task?.task_type?.type
          )
        "
        class="d-flex align-center mr-1"
      >
        <v-icon v-if="task?.task_type?.type === 'workflow'" dense
          >mdi-source-branch</v-icon
        >
        <v-icon v-else-if="task?.task_type?.type === 'rfi'" dense
          >mdi-message-text</v-icon
        >
        <v-icon
          v-else-if="task?.task_type?.type === 'dhme-quality-assurance'"
          dense
          >{{
            task?.task_type?.custom_2 === 'leanform'
              ? 'mdi-receipt-text-arrow-right'
              : 'mdi-file'
          }}</v-icon
        >
      </div>
      <div class="ant-gantt-event-label fs-12" @click.stop="clickTask(task)">
        {{ task.title }}
      </div>
      <v-spacer />
      <div
        v-if="showLabels && task.labels && task.labels.length > 0"
        class="mr-2 d-flex overflow-hidden"
      >
        <v-chip
          v-for="label in task.labels"
          :key="label.id"
          label
          x-small
          class="mr-1"
          :color="label.color"
          :text-color="getTextContrastColor(label.color)"
          >{{ label.title }}</v-chip
        >
      </div>
      <slot name="task-header-icon"></slot>
      <v-menu v-if="showOptions" right transition="slide-x-transition">
        <template #activator="{ on, attrs }">
          <v-icon v-bind="attrs" small class="task-options" v-on="on"
            >mdi-dots-vertical</v-icon
          >
        </template>

        <v-list dense>
          <v-list-item @click="$refs['add-child-task-dialog'].displayed = true">
            <v-icon dense class="mr-2">mdi-plus</v-icon>
            <v-list-item-title>Create subtask</v-list-item-title>
          </v-list-item>
          <v-list-item @click="selectTask(task)">
            <v-icon dense class="mr-2">mdi-delete</v-icon>
            <v-list-item-title>Delete task</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-if="task.children && task.children.length > 0 && !task.collapsed">
      <tasks-gantt-default-item
        v-for="(child, childIndex) in task.children"
        :key="child.id"
        :task="child"
        :index="childIndex + index + 1"
        :parents="parentTreeItems"
        :show-labels="showLabels"
        :show-options="showOptions"
        @clickedTask="clickTask"
        @toggleCollapse="toggleCollapse"
      />
    </div>
    <delete-tasks-dialog
      :tasks="selectedTasks"
      :is-shown="tasksDeleteDialog"
      @close-dialog="onDeleteDialogClose"
      @on-delete="reloadTree"
    />
    <add-task-dialog
      ref="add-child-task-dialog"
      :parent="task.id"
      :project="task.task_project"
      @success="reloadTree"
    />
  </div>
</template>

<script>
import TaskHelper from '@/services/task-helper';
import ColorHelper from '@/services/color-helper';
import moment from 'moment';
import DeleteTasksDialog from '@/components/Tasks/DeleteTasksDialog.vue';
import { mapGetters } from 'vuex';
import AddTaskDialog from '@/components/Tasks/AddTaskDialog.vue';

export default {
  name: 'TasksGanttDefaultItem',
  components: { AddTaskDialog, DeleteTasksDialog },
  props: {
    task: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parents: {
      type: Array,
      required: true,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    canPlan: {
      type: Boolean,
      default: true,
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    showSbs: {
      type: Boolean,
      default: false,
    },
    canLoadChildren: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      parentTreeItems: [],
      taskStartDate: null,
      taskEndDate: null,
      today: moment().format('YYYY-MM-DD'),
      menus: {
        start_date: false,
        end_date: false,
        planning: false,
      },
      tasksDeleteDialog: false,
      selectedTasks: [],
    };
  },
  computed: {
    ...mapGetters(['selectedLicense']),
  },
  watch: {
    'task.collapsed'(value) {
      if (!value) {
        this.$store.dispatch('fetchTaskChildren', {
          event: this.task,
          parents: this.parentTreeItems,
        });
      } else {
        this.$emit('redrawRelations');
      }
    },
  },
  beforeMount() {
    if (this.task.hasChildren) {
      this.parentTreeItems = [...this.parents];
      this.parentTreeItems.push(this.task.id);
    }
  },
  methods: {
    reloadTree() {
      this.$store.dispatch('fetchTaskTree', {
        'filter[parent]': '',
        'filter[license]': this.selectedLicense.id,
      });
    },
    selectTask(task) {
      this.tasksDeleteDialog = true;
      this.selectedTasks = [];
      this.selectedTasks.push(task);
    },
    onDeleteDialogClose(state) {
      this.tasksDeleteDialog = state;
      this.$store.dispatch('fetchTaskTree', {
        'filter[parent]': '',
        'filter[license]': this.selectedLicense.id,
      });
    },
    clickTask(task) {
      this.$emit('clickedTask', task);
    },
    toggleCollapse({ task, parents }) {
      this.$emit('toggleCollapse', {
        task: task,
        parents: parents,
      });
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    getTextContrastColor(color) {
      return ColorHelper.getBlackOrWhiteBasedOnBackground(color);
    },
  },
};
</script>

<style scoped lang="scss">
.gantt-task-item {
  height: 36px;
  border-bottom: solid 1px gainsboro;
  transition: 200ms ease-in;

  .task-options {
    opacity: 0;
    transition: 200ms ease-in;
  }
  &:hover {
    background-color: whitesmoke;

    .task-options {
      opacity: 1;
    }
  }
}
.ant-gantt-collapse-toggle-hide {
  opacity: 0;
  pointer-events: none;
}

.ant-gantt-event-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    color: var(--v-primary-base);
    text-decoration: underline;
  }
}
</style>
